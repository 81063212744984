<template>
  <div class="login flex">
    <div class="topRight common">
      <img src="../../assets/img/animalTop.png" alt="">
    </div>
    <div class="bottomLeft common">
      <img src="../../assets/img/animalBottom.png" alt="">
    </div>
    <div class="loginContent">
      <div class="content">
        <div class="logo"><img src="../../assets/img/ysLogo.png" alt=""></div>
        <div class="start flex">
          <div class="left">
            <!--            <img src="../../assets/img/ysLogo.png" alt="" class="logoT">-->
            <h1>{{ $store.state.setting.systemName }}</h1>
            <h4>{{ $store.state.setting.enName }}</h4>
            <el-form ref="formInline" :rules="rules" :model="formInline" class="formInline">
              <el-form-item prop="companyCode">
                <el-input prefix-icon="el-icon-key" placeholder="请输入企业代码"
                          v-model="formInline.companyCode" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-input prefix-icon="el-icon-mobile-phone" placeholder="请输入用户名"
                          v-model="formInline.preUsername" clearable></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input placeholder="请输入密码" prefix-icon="el-icon-lock" type="password"
                          v-model="formInline.password" clearable></el-input>
              </el-form-item>
              <el-form-item class="imageCode">
                <el-input placeholder="请输入图形验证码" prefix-icon="el-icon-unlock"
                          v-model="formInline.imageCode"></el-input>
                <img :src="imgSrc" alt="" class="code" @click="reloadImg">
              </el-form-item>
              <el-form-item class="btnGroup">
                <el-button type="primary" size="small" @click="handleSubmit('formInline')">登录</el-button>
                <el-link :underline="false" href="forget">忘记密码</el-link>
                <el-link :underline="false" href="welcome">返回</el-link>
              </el-form-item>
            </el-form>
            <div class="littleFeet">
              <img src="../../assets/img/feetS.png" alt="" class="feetImgS ">
              <img src="../../assets/img/feetL.png" alt="" class="feetImgL">
            </div>
          </div>
          <div class="right">
            <img src="../../assets/img/animalSys.png" alt="" width="100%">
            <img src="../../assets/img/mouse.png" alt="" width="90%" class="mouse">
          </div>
        </div>
      </div>
      <div class="footer" v-html=" $store.state.setting.copyright"></div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex'

export default {
  name: "login",
  data() {
    return {
      formInline: {
        companyCode: '',
        preUsername: '',
        password: '',
        imageCode: ''
      },
      imgSrc: "",
      text: '',
      rules: {
        companyCode: [
          {required: true, message: '企业代码不能为空', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '密码不能为空', trigger: 'blur'},
          {type: 'string', min: 3, message: '密码长度不能少于三位', trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {
    this.reloadImg();
  },
  methods: {
    reloadImg() {
      this.uuid();
      this.imgSrc = this.$local.baseURL() + "/code/image?deviceId=" + this.text
    },
    uuid() {
      let s = [];
      let hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      let uuid = s.join("");
      this.text = uuid
    },
    handleSubmit(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          this.formInline.deviceId = this.text
          let openId = localStorage.getItem("openId")
          if (openId != undefined && openId != null && openId != "undefined") {
            this.formInline.openId = openId
          }
          let user = this.formInline
          this.$post('/login-c', user).then(res => {
            let data = res.data;
            this.saveData(data)
            this.$message.success("登录成功");
            this.$router.push({path: '/sys'})
          }).catch(error => {
            return false;
          })

        } else {
          return false;
        }
      })
    },
    ...mapMutations({
      setToken: 'account/setToken',
      setExpireTime: 'account/setExpireTime',
      setPermissions: 'account/setPermissions',
      setRoles: 'account/setRoles',
      setUser: 'account/setUser'
    }),
    saveData(data) {
      this.setToken(data.token)
      this.setExpireTime(data.expireTime)
      this.setUser(data.user)
      this.setPermissions(data.permissions)
      this.setRoles(data.roles)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/login";
</style>
